import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, redirect, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import TopNavbar from "../../components/top-navbar.component";
import { VIEWS } from "../../routes/routes";
import Footer from "../../components/footer.component";
import useToken from "../../hooks/useToken";
import homeBannerImg from "../../common/assets/images/home_banner.png";
import HeaderMarketingBanner from "../../components/header-marketing-banner.component";
import { forgotPassword, signInUser } from "../../services/auth-api.service";
import InputField from "../../components/input-field.component";
import Button from "../../components/button.component";
import ButtonSecondary from "../../components/button-secondary.component";
import BrandsBanner from "../../components/brands-banner.compoment";
import Testimonials from "../../components/testimonials.component";

export default function Home() {
  useScrollToTop();
  const navigate = useNavigate();
  const { token, setToken, clearToken } = useToken();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [forgotPwEmail, setForgotPwEmail] = useState("");
  const checkEmail = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
  const [isLoading, setIsLoading] = useState(false);
  const [isDisplayForgotPw, setIsDisplayForgotPw] = useState(false);
  const [isSentForgotPwEmail, setIsSentForgotPwEmail] = useState(false);

  //If the token is available public pages are unavailable
  useEffect(() => {
    if (token) {
      navigate(VIEWS.USER_DASHBOARD);
    }
  }, [token]);

  const handleFormAction = async () => {
    if (email === "") {
      toast.error("Please enter email");
    } else if (!checkEmail.test(email)) {
      toast.error("Please enter valid email");
    } else if (password === "") {
      toast.error("Please enter password");
    } else {
      try {
        setIsLoading(true);
        const response = await signInUser(email, password);
        if (
          response?.data?.success &&
          response?.data?.data?.user?.isAccountActivate
        ) {
          setToken(response?.data?.data?.token);
          localStorage.setItem(
            "user",
            JSON.stringify(response?.data?.data?.user)
          );
          setEmail("");
          setPassword("");
          setIsLoading(false);
          navigate(VIEWS.USER_UPLOAD);
          window.location.reload();
        } else if (!response?.data?.success) {
          setEmail("");
          setPassword("");
          setIsLoading(false);
          toast.error(response?.error);
        }
      } catch (error: any) {
        setIsLoading(false);
      }
    }
  };

  const handleFormActionForgotPw = async () => {
    if (forgotPwEmail === "") {
      toast.error("Please enter email");
    } else if (!checkEmail.test(forgotPwEmail)) {
      toast.error("Please enter valid email");
    } else {
      try {
        setIsLoading(true);
        const response = await forgotPassword(forgotPwEmail);
        if (response.success) {
          setForgotPwEmail("");
          setIsLoading(false);
          toast.success(response.data?.message);
          setIsSentForgotPwEmail(true);
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } catch (error: any) {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleFormAction();
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col min-h-screen p-5 lg:p-0">
        {/* <HeaderMarketingBanner /> */}
        <TopNavbar />
        <div className="w-full flex flex-col md:flex-row justify-center items-center min-h-auto mt-4 bg-gradient-bottom-top">
          {/* Left side container */}
          <div className="w-full lg:w-1/2 lg:pl-12 xl:pl-24 h-fit flex justify-center items-center flex-col">
            <h4 className="text-xl text-secondary-dark text-center font-semibold">
              Vikalp : HIRING SUITE FOR RECRUITERS
            </h4>
            <h4 className="text-4xl text-primary-dark text-center font-semibold mt-4">
              From Campus to Senior Level Hiring
            </h4>
            <h4 className="text-3xl text-primary-light text-center font-normal mt-3 mb-20">
              Bouquet of solution to meet all your hiring needs
            </h4>
            <img
              src={homeBannerImg}
              alt="Vikalp"
              className="mt-16 md:mt-32 lg:mt-0"
            />
          </div>
          {/* Right side container */}
          <div className="w-full lg:w-1/2 lg:pl-12 xl:pl-24 h-auto flex justify-start items-center md:items-start flex-col">
            <div className="w-3/4 h-auto bg-white flex justify-center items-start rounded-xl shadow-custom my-20 mx-20">
              {isDisplayForgotPw ? (
                <div className="w-full bg-light px-5 py-20 rounded-xl mx-10">
                  <h1 className="text-slate-800 font-bold text-center text-3xl mb-12">
                    Forgot password
                  </h1>
                  {isSentForgotPwEmail ? (
                    <div className="flex flex-col justify-center items-center">
                      <p className="block text-gray-700 text-md font-normal text-center my-8">
                        We have sent instructions to reset your password to your
                        email. <br /> Please check your inbox.
                      </p>
                      <i className="fa-regular fa-circle-check text-blue-500 font-semibold text-8xl text-center"></i>
                      <div className="w-full mt-24">
                        <ButtonSecondary
                          name={"Go back to the Login"}
                          handleAction={() => {
                            setIsDisplayForgotPw(false);
                            setIsSentForgotPwEmail(false);
                          }}
                        />
                      </div>
                    </div>
                  ) : (
                    <>
                      <p className="block text-gray-700 text-sm font-normal text-center my-8">
                        We'll send you instructions to reset your password{" "}
                        <br />
                        and get you back on track.
                      </p>
                      <InputField
                        icon="fa-solid fa-envelope"
                        type="email"
                        label="Email *"
                        placeholder="Enter your email"
                        value={forgotPwEmail}
                        onChange={(e: any) => setForgotPwEmail(e.target.value)}
                        onKeyDown={handleKeyPress}
                      />
                      <Button
                        name={"Email me"}
                        handleAction={handleFormActionForgotPw}
                      />
                      <p className="block text-primary-light text-sm text-center my-8">
                        or
                      </p>
                      <div className="flex items-center my-8">
                        <div className="flex-grow border-t border-primary-light"></div>
                        <p className="block text-primary-light text-sm text-center mx-4">
                          Already have password?
                        </p>
                        <div className="flex-grow border-t border-primary-light"></div>
                      </div>
                      <ButtonSecondary
                        name={"Log into account"}
                        handleAction={() => setIsDisplayForgotPw(false)}
                      />
                    </>
                  )}
                </div>
              ) : (
                <div className="w-full bg-light px-5 py-20 rounded-xl mx-10">
                  <h1 className="text-slate-800 font-bold text-center text-3xl mb-12">
                    User Login
                  </h1>
                  <InputField
                    icon="fa-solid fa-envelope"
                    type="email"
                    label="Email ID *"
                    placeholder="Enter registered email ID"
                    value={email}
                    onChange={(e: any) => setEmail(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                  <InputField
                    icon="fa-solid fa-lock"
                    type="password"
                    label="Password *"
                    placeholder="Enter password"
                    value={password}
                    onChange={(e: any) => setPassword(e.target.value)}
                    onKeyDown={handleKeyPress}
                  />
                  <p className="block text-gray-700 text-sm font-semibold text-center my-5">
                    <button
                      className="text-blue-800 hover:underline"
                      onClick={() => setIsDisplayForgotPw(!isDisplayForgotPw)}
                    >
                      Forgot password?
                    </button>
                  </p>
                  <Button name={"Log In"} handleAction={handleFormAction} />
                  <p className="block text-primary-light text-sm text-center my-8">
                    or
                  </p>
                  <div className="flex items-center my-8">
                    <div className="flex-grow border-t border-primary-light"></div>
                    <p className="block text-primary-light text-sm text-center mx-4">
                      Don’t have a registered email ID?
                    </p>
                    <div className="flex-grow border-t border-primary-light"></div>
                  </div>
                  <ButtonSecondary
                    name={"Create account"}
                    handleAction={handleFormAction}
                  />
                </div>
              )}
            </div>
          </div>
        </div>
        <Testimonials />
      </div>
      <BrandsBanner />
      <Footer />
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
