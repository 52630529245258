import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, redirect, useLocation } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import LoadingSpinner from "../../components/loading-spinner.component";
import { useScrollToTop } from "../../common/hooks/use-scroll-to-top";
import TopNavbar from "../../components/top-navbar.component";
import { VIEWS } from "../../routes/routes";
import Footer from "../../components/footer.component";
import useToken from "../../hooks/useToken";
import homeBannerImg from "../../common/assets/images/home_banner.png";
import HeaderMarketingBanner from "../../components/header-marketing-banner.component";
import { activateAccount, signInUser } from "../../services/auth-api.service";
import InputField from "../../components/input-field.component";
import Button from "../../components/button.component";

export default function ActivateAccount() {
  useScrollToTop();
  const navigate = useNavigate();
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const { token, setToken, clearToken } = useToken();

  //Getting the token from the URL params
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const accessToken: string = searchParams.get("token") ?? "";

  //If the token is available public pages are unavailable
  useEffect(() => {
    if (token) {
      navigate(VIEWS.USER_DASHBOARD);
    }
  }, [token]);

  useEffect(() => {
    if (accessToken === "") {
      navigate(VIEWS.HOME);
    }
  }, [accessToken]);

  const handleFormAction = async () => {
    if (accessToken === "") {
      toast.error("Invalid account activate token");
    } else if (password === "") {
      toast.error("Please enter new password");
    } else if (password !== confirmPassword) {
      toast.error("Confirm password is not match");
    } else {
      try {
        setIsLoading(true);
        const response = await activateAccount(
          accessToken,
          password,
          confirmPassword
        );
        if (response.success) {
          setPassword("");
          setConfirmPassword("");
          setIsLoading(false);
          setTimeout(() => {
            toast.success(response.data?.message);
          }, 750);
          navigate(VIEWS.HOME);
        } else {
          setIsLoading(false);
          toast.error(response.error);
        }
      } catch (error: any) {
        setIsLoading(false);
      }
    }
  };

  const handleKeyPress = (event: any) => {
    if (event.key === "Enter") {
      handleFormAction();
    }
  };

  return (
    <React.Fragment>
      <div className="flex flex-col min-h-screen p-5 lg:p-0">
        {/* <HeaderMarketingBanner /> */}
        <TopNavbar />
        <div className="w-full flex flex-col md:flex-row justify-center items-center min-h-auto mt-4 bg-gradient-bottom-top">
          {/* Left side container */}
          <div className="w-full lg:w-1/2 lg:pl-12 xl:pl-24 h-fit flex justify-center items-center flex-col">
            <h4 className="text-xl text-secondary-dark text-center font-semibold">
              Vikalp : HIRING SUITE FOR RECRUITERS
            </h4>
            <h4 className="text-4xl text-primary-dark text-center font-semibold mt-4">
              From Campus to Senior Level Hiring
            </h4>
            <h4 className="text-3xl text-primary-light text-center font-normal mt-3 mb-20">
              Bouquet of solution to meet all your hiring needs
            </h4>
            <img
              src={homeBannerImg}
              alt="Vikalp"
              className="mt-16 md:mt-32 lg:mt-0"
            />
          </div>
          {/* Right side container */}
          <div className="w-full lg:w-1/2 lg:pl-12 xl:pl-24 h-auto flex justify-start items-center md:items-start flex-col">
            <div className="w-3/4 h-auto bg-white flex justify-center items-start rounded-xl shadow-custom my-20 mx-20">
              <div className="w-full bg-light px-5 py-20 rounded-xl mx-10">
                <h1 className="text-slate-800 font-bold text-center text-3xl mb-12">
                  Add New Password
                </h1>
                <p className="block text-gray-700 text-sm font-normal text-center my-8">
                  Please enter your new password below. Make sure to choose a
                  strong password that you haven't used before. After setting
                  your new password, you will be able to access your account
                  with your updated credentials.
                </p>
                <InputField
                  icon="fa-solid fa-lock"
                  type="password"
                  label="New Password *"
                  placeholder="Enter password"
                  value={password}
                  onChange={(e: any) => setPassword(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <InputField
                  icon="fa-solid fa-lock"
                  type="password"
                  label="Confirm Password *"
                  placeholder="Enter confirm password"
                  value={confirmPassword}
                  onChange={(e: any) => setConfirmPassword(e.target.value)}
                  onKeyDown={handleKeyPress}
                />
                <Button
                  name={"Activate Account"}
                  handleAction={handleFormAction}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {isLoading ? <LoadingSpinner /> : null}
      <ToastContainer />
    </React.Fragment>
  );
}
