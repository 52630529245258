import { serializeParams } from "../common/utils/serialize-params";

// Base URL
const BASE_URL = process.env.REACT_APP_BACKEND_API;

// API Endpoints
export const ENDPOINT = {
  AUTH: {
    ROOT: `${BASE_URL}auth`,
    SIGN_UP: `${BASE_URL}auth/sign-up`,
    SIGN_IN: `${BASE_URL}auth/sign-in`,
    ACTIVATE_ACCOUNT: `${BASE_URL}auth/activate-account`,
    VERIFY_OTP: `${BASE_URL}auth/verify-otp`,
    GENERATE_OTP: `${BASE_URL}auth/generate-otp`,
    FORGOT_PASSWORD: `${BASE_URL}auth/forgot-password`,
    RESET_PASSWORD: `${BASE_URL}auth/reset-password`,
    CHANGE_PASSWORD: `${BASE_URL}auth/change-password`,
  },
  USER: {
    ROOT: `${BASE_URL}user`,
    ROOT_WITH_ID: (id: string) => `${BASE_URL}user/${id}`,
    ROOT_WITH_PAGINATOR: (
      currentPage: number,
      pageSize: number,
      searchQuery: string
    ) => {
      let url = `${BASE_URL}user?page=${currentPage}&take=${pageSize}`;
      if (searchQuery !== "") {
        url += `&search=${searchQuery}`;
      }
      return url;
    },
  },
  COMPANY: {
    ROOT: `${BASE_URL}company`,
    ROOT_WITH_ID: (id: string) => `${BASE_URL}company/${id}`,
    ROOT_WITH_PAGINATOR: (
      currentPage: number,
      pageSize: number,
      searchQuery: string
    ) => {
      let url = `${BASE_URL}company?page=${currentPage}&take=${pageSize}`;
      if (searchQuery !== "") {
        url += `&search=${searchQuery}`;
      }
      return url;
    },
  },
  RESUME: {
    ROOT: `${BASE_URL}resume`,
    ROOT_WITH_ID: (id: string) => `${BASE_URL}resume/${id}`,
    UPLOAD_WITH_PDF: `${BASE_URL}resume/create-with-file`,
    UPLOAD_WITH_TEXT: `${BASE_URL}resume/create-with-text`,
    UPLOAD_WITH_CSV: `${BASE_URL}resume/create-with-csv`,
    DOWNLOAD_CV_DATA_AS_CSV: `${BASE_URL}resume/download-cv-as-csv`,
    ROOT_WITH_PAGINATOR: (
      currentPage: number,
      pageSize: number,
      searchParams: Record<string, any>
    ) => {
      let url = `${BASE_URL}resume?page=${currentPage}&take=${pageSize}`;
      const queryParams = serializeParams(searchParams);
      if (queryParams) {
        url += `&${queryParams}`;
      }
      return url;
    },
    ALL_RESUME_LIST_WITH_PAGINATOR: (
      currentPage: number,
      pageSize: number,
      searchQuery: string
    ) => {
      let url = `${BASE_URL}resume/admin/all?page=${currentPage}&take=${pageSize}`;
      if (searchQuery !== "") {
        url += `&search=${searchQuery}`;
      }
      return url;
    },
    SEARCH: `${BASE_URL}resume/search`,
  },
  SAVED_SEARCH: {
    ROOT: `${BASE_URL}saved-search`,
    ROOT_WITH_ID: (id: string) => `${BASE_URL}saved-search/${id}`,
    ROOT_WITH_SEARCH_TYPE: (search_type: string) =>
      `${BASE_URL}saved-search?type=${search_type}`,
  },
  METRICS: {
    ROOT: `${BASE_URL}metrics`,
  },
  TYPES: {
    COMPANY_TYPE_LIST: `${BASE_URL}types/companies-list`,
    LOCATION_TYPE_LIST: `${BASE_URL}types/locations-list`,
  },
};
